import React, {
  FC,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import { Alerts } from "@acin.reactjs/alert";
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimaveraPage from './components/pages/primavera/PrimaveraPage';
import SelosTemporaisPage from './components/pages/selosTemporais/SelosTemporaisPage';
import OnlyofficePage from './components/pages/onlyoffice/OnlyofficePage';
import CookieComponent from './components/cookies/CookieComponent';
import { globalConfig, customAlerts } from './config/AlertsConfig';
import AppTheme from './global-style';
import Content from './components/layout/content/Content';
import Faqs from './components/sections/faqs/FaqsComponent';
import AcinGroup from './components/pages/acinGroup/AcinGroup';
import Error404 from './components/errors/Error404';
import MobilePage from './components/pages/mobile/MobilePage';
import GestaoSistemasPage from './components/pages/gestaoSistemas/GestaoSistemasPage';
import PublicRoute from './components/typeRoutes/PublicRoute';
import { CallMeUI } from '@acin.js-tools/call-me-ui';
import TrialFormProvider from './context/TrialFormContext';
import RedirectRoute from './components/typeRoutes/RedirectRoute';
import CallUiConfig from './config/CallUiConfig';
import { useTranslation } from 'react-i18next';

const App: FC = () => {
  const localStorageBaseName     = window.localStorage.getItem('baseName');
  const showCallUi               = window.localStorage.getItem('showCallUi');
  const { t: translation, i18n } = useTranslation();

  if (showCallUi) {
    CallMeUI?.init(CallUiConfig(translation));
  }

  useEffect(() => {
    if (showCallUi) {
      CallMeUI?.configComponents(CallUiConfig(translation, i18n.language.toLowerCase()));
    }
  }, [i18n.language]);

  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
          <Router basename={localStorageBaseName}>
            <TrialFormProvider>
            <Alerts customAlerts={customAlerts} config={globalConfig}>
              <Switch>
                <PublicRoute
                  exact path="/:lang(PT|ES|GB|pt|es|gb)?"
                  component = {Content}
                />
                <PublicRoute
                  path="/faqs/:lang(PT|ES|GB|pt|es|gb)?"
                  component= {Faqs}
                  footerShadow
                />
                <PublicRoute
                  exact path='/qualidade/:lang(PT|ES|GB|pt|es|gb)?'
                  component={AcinGroup}
                />
                <PublicRoute
                  exact
                  path="/app-gestao-documental/:lang(PT|ES|GB|pt|es|gb)?"
                  component={MobilePage}
                />
                <PublicRoute
                  exact
                  path="/gestao-sistemas/:lang(PT|ES|GB|pt|es|gb)?"
                  component={GestaoSistemasPage}
                />
                <PublicRoute
                  exact
                  path="/primavera/:lang(PT|ES|GB|pt|es|gb)?"
                  component={PrimaveraPage}
                />
                <PublicRoute
                  exact
                  path="/selos-temporais/:lang(PT|ES|GB|pt|es|gb)?"
                  component={SelosTemporaisPage}
                />
                <PublicRoute
                  exact
                  path="/onlyoffice/:lang(PT|ES|GB|pt|es|gb)?"
                  component={OnlyofficePage}
                />
                <RedirectRoute
                  path="/acingroup/:lang(PT|ES|GB|pt|es|gb)?"
                  redirectPath='/#acin'
                />
                <PublicRoute
                  component={Error404}
                  footer={false}
                />
              </Switch>
              <CookieComponent />
            </Alerts>
            </TrialFormProvider>
          </Router>
    </ThemeProvider>
  )
};

export default App;
