import { constants } from './../global-constants';
import { postRequest } from './../components/requestComponent/postRequest';

function replaceVarString(string, vars) {
  let newString = string;
  for (let key in vars) {
    newString = newString.replace(`{${key}}`, vars[key]);
  }
  return newString;
}

const i18nLocales = {
  pt: 'pt',
  es: 'es',
  gb: 'en'
}

const CallUiConfig = (t, l = 'pt') => ({
  intro: {
    title: t('callUiIntroTitle'),
    subtitle: t('callUiIntroSubtitle'),
    description: t('callUiIntroDescription'),
    buttonTitle: t('callUiIntroButtonTitle'),
  },
  callMe: {
    availability: t('callUiCallMeAvailability'),
    buttonTitle: t('callUiCallMeButtonTitle'),
    description: t('callUiCallMeDescription'),
    numberPlaceholder: t('callUiCallMeNumberPlaceholder'),
    policy: {
      text: t('callUiCallMePolicyText'),
      link: replaceVarString(constants.links.dynamicPrivacyPolicy, { l: i18nLocales[l] ?? 'pt' })
    },
    subtitle: t('callUiCallMeSubtitle'),
    title: t('callUiCallMeTitle'),
    validation: {
      invalidNumber: t('callUiCallMeValidationInvalidNumber'),
      invalidPolicy: t('callUiCallMeValidationInvalidPolicy')
    }
  },
  delay: 100,
  success: {
    description: t('callUiSuccessDescription'),
    iconUrl: t('callUiSuccessIconUrl'),
    subtitle: t('callUiSuccessSubtitle'),
    title: t('callUiSuccessTitle')
  },
  error:{
    message: t('callUiErrorMessage'),
    status: false
  },
  show: true,
  callMeCallback: async (phoneNumber) => {
    try {
      let state = await new Promise((resolve) => {
        postRequest('call', {
          postData: { destinationNumber: phoneNumber },
          source: '10',
          successRequest: async () => resolve(true),
          errorRequest: async () => resolve(false)
        });
      });
      return state;
    } catch (error) {
      return false;
    }
  }
});

export default CallUiConfig;
