export const listMobile = [
  'App compatível com sistemas operativos Android (Google), iOS (Apple) e HarmonyOS (Huawei);',
  'Autentique-se com as suas credenciais iDOK ou através das credenciais GTS, prestador de assinaturas digitais (apenas para clientes GTS);',
  'Tenha acesso a todas as entidades associadas ao seu perfil de utilizador;',
  'Consulte e efetue filtros de pesquisa por documentos enviados, recebidos, entre outros;',
  'Visualize documentos, entidades associadas e encaminhamentos realizados;',
  'Associe despachos, pareceres ou informações;',
  'Atribua prazos e níveis de prioridades;',
  'Encaminhe documentos para departamentos e/ou utilizadores específicos;',
  'Marque documentos para assinar;',
  'Assine um documento ou múltiplos documentos em simultâneo com a GTS (assinatura em lote);',
  'Valide a sua assinatura digital GTS através de código OTP, pin, impressão digital (ou Face ID, para utilizadores do sistema operativo iOS);',
  'Seja notificado, em tempo real, da receção de novos documentos.'
];

export default {
  translation: {
    idokLogo: 'Gestão Documental iDOK',
    login: 'Login',
    features: 'Caraterísticas',
    gestaoSistemas: 'Gestão de Sistemas',
    prices: 'Preços',
    news: 'Notícias',
    requestTest: 'TESTE GRÁTIS',
    requestPrices: 'Pedir Orçamento',
    request: 'Pedir',
    confirmTest: 'Confirmar',
    watchVideo: 'VER VÍDEO',
    talkToUs: 'Fale connosco',
    telephone: '707 451 451. ',
    idokSupportEmail: 'apoio@idok.pt. ',
    enter: 'Entrar',
    mainContentHome:
      'O iDOK é um software de Gestão Documental simples e intuitivo que permite registar, consultar e arquivar toda a documentação, em qualquer lugar. Desmaterialize e organize o funcionamento interno da sua entidade.',
    makeYourTitleHome: 'TORNE A SUA',
    managementTitleHome: 'GESTÃO',
    documentaryTitleHome: 'DOCUMENTAL',
    lighterTitleHome: 'MAIS LEVE',
    lighterTitleHome2: 'MAIS EFICIENTE',
    lighterTitleHome3: 'MAIS SUSTENTÁVEL',
    lighterTitleHome4: 'MAIS FÁCIL',
    normalTitleFeatures: 'O essencial para a sua',
    highlightTitleFeatures: 'Entidade fluir!',
    normalTitleIntegrations: 'Conheça as',
    highlightTitleIntegrations: 'integrações do iDOK',
    subtitleIntegrations: 'Automatize a sua Gestão Documental',
    integrations: 'Integrações',
    featuresDetails: 'Funcionalidades',
    knowMore: 'Saber mais',
    documentManagementTitle: 'Gestão de Documentos',
    documentManagementContent:
      'Seja por scanner ou por upload, os documentos carregados ficam sempre disponíveis para classificação e, posteriormente, são arquivados no sistema. Estes documentos podem ainda ser definidos como modelos.',
    modelManagement: 'Gestão de modelos',
    documentClassification: 'Classificação de documentos',
    opticalCharacterRecognition: 'Reconhecimento ótico de carateres',
    filesManagement: 'Gestão de arquivos',
    documentSeries: 'Séries de documentos',
    customisableFields: 'Campos personalizáveis',
    mef: 'MEF (Macroestrutura)',
    associationOfResponseDeadlines: 'Associação de prazos de resposta',
    workflowTitle: 'Workflow',
    workflowContent:
      'Crie processos de workflow para o seguimento dos documentos. As etapas são tratadas apenas por um grupo restrito de pessoas, definido pela entidade. Desta forma, faz uma gestão completa do ciclo de vida da documentação da sua entidade.',
    search: 'Pesquisa',
    documentsDistribution: 'Distribuição de documentos',
    processingFlows: 'Tramitação de fluxos',
    deadlinesAndReports: 'Prazos e relatórios',
    flowchartsCreation: 'Criação de fluxogramas',
    sendingAlertsAndEmails: 'Envio de alertas e emails',
    rulesOfCommunicationWithWebServices: 'Regras de comunicação com web services',
    processesTitle: 'Gestão de Processos',
    processesContent:
      'Uma pasta virtual, agregadora de documentos, que permite associar ficheiros a um determinado processo, para garantir que toda a informação se encontre organizada e centralizada. Poderá ainda atribuir gestores, consultar e exportar informações.',
    personalProcessManagement: 'Gestão pessoal de processos',
    exportProcessListing: 'Exportação listagem de processos',
    assignmentOfManagers: 'Atribuição de gestores',
    safetyTitle: 'Segurança',
    safetyContent:
      'Tenha acesso a uma área de gestão com grupos de utilizadores na qual é possível definir as permissões e responsabilidades para cada um deles, assim como definir o acesso por utilizador e perfil de utilizador a determinadas funcionalidades e conteúdos.',
    restrictedUserAccess: 'Acesso restrito de utilizadores',
    reports: 'Relatórios',
    accessRegistration: 'Registo de acesso',
    digitalSignatureTitle: 'Assinatura Digital',
    digitalSignatureContent:
      'Realize no seu computador a assinatura digital dos documentos carregados e encaminhados, através do ',
    digitalSignatureSecondContent:
      ' da GTS – Global Trusted Sign ou a partir do Cartão de Cidadão. Desta forma, garante a autoria dos seus documentos.',
    digitalSignatureHref: 'certificado digital qualificado',
    ownershipGuarantee: 'Garantia de titularidade',
    batchSubscription: 'Assinatura em lote',
    severalSignatures: 'Possibilidade de constar várias assinaturas no mesmo documento',
    customSolutionTitle: 'Solução Customizada',
    customAutomationTitle: 'Automatização',
    customGestaoSistemasTitle: 'Módulo Gestão de Sistemas',
    customPortalServicosTitle: 'Portal de Serviços',
    customSolutionContent:
      'O iDOK é totalmente configurável, de modo a que fique à medida da sua entidade. Pode adicionar novas funcionalidades que complementam a Gestão Documental, o que proporciona um ambiente mais intuitivo.',
    customAutomationContent: 'Com o nosso software asseguramos um avanço sem precedentes nos processos administrativos. O iDOK aposta na automatização de modo a proporcionar eficiência e redução de erros. Modernize a sua gestão documental.',
    customGestaoSistemasContent: 'O Módulo de Gestão de Sistemas é a resposta que procura para fins de Auditoria. É um forte aliado para o cumprimento de políticas, processos e procedimentos, com a possibilidade de limitar o acesso a determinados documentos.',
    customPortalServicosContent: 'A plataforma iDOK permite integrar portais externos que facilitam o acesso a serviços.',
    automaticIndex: 'Indexação automática',
    notificationAlert: 'Notificações e alertas',
    fluxoDinamico: 'Fluxo de trabalho dinâmico',
    integracaoSistemasExterno: 'Integração com sistemas externos',
    gestaoVersaoAutomatica: 'Gestão de versões automática',
    gestaoModelos: 'Gestão de modelos',
    registoAutenticacao: 'Registo e autenticação do público-alvo',
    solicitacaoPedidos: 'Solicitação de pedidos online e consulta de serviços informativos',
    envioDadosPedidos: 'Preenchimento/envio de dados e submissão de pedidos para análise',
    geracaoPagamentos: 'Geração de pagamentos',
    envioSmsEmail: 'Envio de notificação por SMS e e-mail',
    gestaoReservas: 'Gestão de reservas',
    geracaoDocumentosDinamicos: 'Geração de documentos dinâmicos',
    emailStructure: 'Estrutura do email',
    departmentManagement: 'Gestão de departamentos',
    billingInquiry: 'Consulta de faturação',
    entityManagement: 'Gestão de entidades',
    normalTitleFreeTrial: 'Experimente',
    highlightTitleFreeTrial: 'Gratuitamente',
    introFreeTrial:
      'Quer saber mais sobre a desmaterialização documental? Peça-nos uma demonstração e conheça todas as vantagens de um software de Gestão Documental como o iDOK, sem custos adicionais ou limitações.',
    makeRegisterFreeTrial: 'Efetue o seu pré-registo e entraremos em contacto consigo.',
    askQuestionPrimavera: 'Estamos ao seu dispor para responder às suas questões! Simplifique já a sua Gestão Documental.',
    contactForFreeTrial: 'Contacte-nos para aderir ou testar sem compromisso.',
    entity: 'Nome Entidade',
    email: 'E-mail',
    phone: 'Telefone',
    country: 'País',
    portugal: 'Portugal',
    brazil: 'Brasil',
    canada: 'Canadá',
    requiredFields: 'Campos de preenchimento obrigatório.',
    readTerms: 'Declaro que li e aceito as ',
    conditionsTerms: 'Condições Gerais de Adesão',
    codicoesGeraisPdf: 'cgadesao_pt.pdf',
    registerTerms: 'Declaro que tenho conhecimento e aceito a ',
    thanksForm: 'Agradecemos o seu pré-registo, que foi efetuado com sucesso.',
    thanksFormPrimavera: 'Agradecemos o seu interesse na integração com o ERP Primavera.',
    thanksFormSelosTemporais: 'Agradecemos o seu interesse nos Selos Temporais GTS.',
    soonForm: 'Brevemente entraremos em contacto através da nossa linha de apoio.',
    freePrice: '0.00€',
    cloudPrice: '9,90€',
    fiveGbPrice: '40€',
    tenGbPrice: '75€',
    twentyGbPrice: '140€',
    aboveTwentyGbPrice: '+15€',
    eachTwoGb: '/cada 2GB',
    oneGb: '1GB',
    fiveGb: '5GB',
    tenGb: '10GB',
    twentyGb: '20GB',
    free: 'Free',
    perMonth: '/mês',
    until: 'Até ',
    enjoy: 'Usufrua de',
    overThan: 'Superior a ',
    thirtyDaysFree: '30 Dias Grátis',
    withoutCommitment: 'sem compromisso!',
    plansCloud: 'Planos na Cloud',
    otherPlans: 'OUTROS PLANOS',
    normalTitlePrices: 'Um Plano à medida da ',
    highlightTitlePrices: 'Sua Entidade',
    introPrices: 'Cada entidade merece um plano de Gestão Documental personalizada para que tire o máximo partido de todas as suas funcionalidades.',
    titlePrices: 'Aposte na desmaterialização documental e num plano feito à sua medida.',
    tryItForFree: 'Experimente ',
    duringThirtyDays: 'durante 30 dias!',
    normalContentPrice: 'Armazenamento ',
    highlightContentPrice: 'limitado',
    every: 'Todas as ',
    idokFunctionalities: 'Funcionalidades iDOK',
    cloud: 'Cloud',
    since: 'Desde',
    try: 'Experimente ',
    freeForThirtyDays: 'grátis por 30 dias',
    cloudStorage: 'Armazenamento na Cloud ',
    fromOneGb: 'a partir de 1GB',
    onPremise: 'On-Premise',
    priceOnRequest: 'Preço sob consulta',
    normalEntityPlan: 'Precisa de um plano adaptado à realidade da sua entidade?',
    highlightEntityPlan: 'Temos uma solução talhada à sua medida.',
    fullPriceList: 'PREÇÁRIO COMPLETO',
    priceFormText: 'Indique-nos os seus dados, sem compromisso, e enviaremos uma proposta que se adapta à sua realidade.',
    select: 'Selecione',
    workers: 'N.º de Funcionários',
    entityType: 'Tipo de Entidade',
    thanks: 'Obrigado!',
    normalTitleAcin: 'Uma solução da',
    highlightTitleAcin: 'ACIN - iCloud Solutions Lda',
    firstParagraphAcinInit: 'A ',
    firstParagraphAcinLink: 'ACIN - iCloud Solutions Lda',
    firstParagraphAcinEnd: ' é vanguardista na área da Cloud Computing e aposta em soluções práticas, económicas e user-friendly, que vão ao encontro das diferentes necessidades do mercado e clientes.',
    highlightSecondParagraphAcin:'Com mais de 20 anos no mercado, o Grupo ACIN encontra-se em constante expansão, tendo atualmente 4 empresas e 14 plataformas de software.',
    normalSecondParagraphAcin: 'Mas a perspetiva é de que estes números continuem a crescer.',
    thirdParagraphAcin:
      'Fazemos a diferença, todos os dias. Prova disso mesmo são os mais de 180 mil clientes que confiam no nosso trabalho e acreditam nas nossas soluções vanguardistas. A sua satisfação é o nosso maior propósito.',
    highlightNumberClientAcin: '+ 180.000',
    clients: 'clientes',
    normalNumberClientAcin: 'em todo o Mundo',
    highlightBillsAcin: '+ 15.000.000 €',
    normalBillsAcin: 'de faturação',
    highlightEmployeesAcin: '+ 350',
    normalEmployeesAcin: 'colaboradores',
    normalTitleNewsletter: 'Subscreva à newsletter do ',
    highlightTitleNewsletter: 'iDOK!',
    subscribe: 'Subscrever',
    menu: 'Menu',
    contacts: 'Contactos',
    contactsCost: 'Preço máximo a pagar por minuto: 0,09€ (+IVA) para as chamadas originadas nas redes fixas e 0,13€ (+IVA) para as originadas nas redes móveis.',
    serviceHoursText: 'Estamos ao seu dispor, nos dias úteis, das 9h00 às 18h00.',
    addresses: 'Moradas',
    solution: 'Uma solução',
    demo: 'Demonstração',
    maps: 'Ver no mapa',
    copyright: 'Todos os direitos reservados',
    terms: 'Termos e Condições',
    privacyPolicy: 'Política de Privacidade',
    suggestions: 'Elogios Sugestões e Reclamações',
    subTitleFaqs: "FAQ GESTÃO DOCUMENTAL iDOK",
    normalTitleFaqs: 'Como podemos ',
    highlightTitleFaqs: 'Ajudar?',
    searchQuestion: 'Pesquisar pergunta',
    aboutUsQuestionFaqs: 'O que é o iDOK?',
    aboutUsAnswerFaqs:
      'O iDOK é um software de Gestão Documental que permite registar, consultar e arquivar toda a documentação da sua entidade. Esta plataforma totalmente Web, alojada na Cloud, garante uma rigorosa monitorização dos procedimentos internos e a segurança da informação, disponível 24 horas por dia, através de qualquer dispositivo.',
    advantagesQuestionFaqs: 'Quais as vantagens de um software de Gestão Documental?',
    advantagesAnswerFaqs:
      'A gestão de documentos pode ser uma atividade exigente e demorada. Mas a desmaterialização documental evita erros, como a dispersão e extravio de documentos importantes, poupa tempo e recursos.',
    adaptationQuestionFaqs: 'O iDOK está adaptado a qualquer entidade?',
    adaptationAnswerFaqs: 'Sim, sejam organismos públicos ou privados.',
    possibleBrowsersQuestionFaqs: 'Que browsers posso utilizar para aceder à Plataforma de Gestão Documental iDOK?',
    possibleBrowsersAnswerFaqs:
      'O acesso ao iDOK, bem como a correta utilização das funcionalidades disponibilizadas, é assegurado nos seguintes navegadores Web: Google Chrome, Mozilla Firefox, Safari, Opera, Edge.',
    digitalSignatureQuestionFaqs: 'É possível utilizar assinaturas digitais na Plataforma iDOK?',
    digitalSignatureAnswerFaqs: 'O iDOK está preparado para a utilização de assinaturas digitais certificadas, sejam avançadas ou qualificadas.',
    sendAndReceiveDocumentQuestionFaqs: 'O iDOK permite enviar e receber documentos de outras entidades?',
    sendAndReceiveDocumentAnswerFaqs: 'Sim. Desde que as entidades estejam registadas na Plataforma, basta apenas configurar essa opção.',
    documentReceptionQuestionFaqs: 'É possível receber documentos de outras plataformas?',
    documentReceptionAnswerFaqs: 'O iDOK permite a integração, através de mecanismos de webservice, em tempo real dos documentos de outras plataformas.',
    flowChartsQuestionFaqs: 'O iDOK permite a criação de fluxos?',
    flowChartsAnswerFaqs: 'Sim. Poderá definir fluxos para o seguimento dos documentos, entre os vários departamentos, de acordo com a informação introduzida. Deste modo, as etapas ficam ao cuidado de um grupo restrito de utilizadores.',
    govAutenticationQuestionFaqs: 'Para que serve a aplicação AUTENTICAÇÃO.GOV?',
    govAutenticationAnswerFaqs: 'A aplicação AUTENTICAÇÃO.GOV permite a utilização e obtenção dos dados do Cartão de Cidadão.',
    usersQuestionFaqs: 'Quantos utilizadores posso criar no iDOK?',
    usersAnswerFaqs: 'O número de utilizadores é ilimitado.',
    customPermissionsQuestionFaqs: 'As permissões de utilizadores são personalizáveis?',
    customPermissionsAnswerFaqs: 'Sim. É possível definir quais os documentos que podem ser vistos ou editados, ou qual a informação disponível.',
    restrictAccessQuestionFaqs: 'É possível restringir o acesso a determinados utilizadores?',
    restrictAccessAnswerFaqs: 'Poderá facilmente restringir o acesso de utilizadores a determinados departamentos, seja para o encaminhamento, classificação ou pesquisa de documentos. Determine a informação que pode ser introduzida, alterada, eliminada e consultada, limitando o acesso a menus e módulos da plataforma.',
    supportQuestionFaqs: 'O iDOK oferece um serviço de suporte telefónico gratuito?',
    supportAnswerFaqs: 'Sim. Colocamos ao seu dispor uma linha telefónica dedicada: ',
    supportTwoAnswerFaqs: 'Pode também contactar-nos por email através do endereço ',
    supportThreeAnswerFaqs: 'Estamos ao seu dispor, todos os dias úteis, das 8h30 às 19h00, sem qualquer custo adicional.',
    tryAnotherQuestion: 'Tente outra questão...',
    user:'Utilizador',
    password: 'Senha',
    forgetPassword: 'Esqueceu-se da sua senha?',
    authenticate: 'Autenticar',
    loginWithCCContent: 'A autenticação com Cartão de Cidadão permite efetuar login de forma prática e segura no portal.',
    loginWithGtsIdContent: 'A autenticação por GTS ID permite-lhe aceder, com segurança e num único login, às plataformas iDOK e GTS.',
    aceitar: 'Aceitar',
    cookieText: 'O iDOK utiliza cookies para melhorar a sua experiência neste website. Ao navegar está a consentir a sua utilização. Saiba mais sobre a nossa {tagopen} política de cookies {tagclose}',
    menuLogin: {
      opt1: 'Iniciar Sessão',
      opt2: 'Cartão de Cidadão',
      opt3: 'GTS ID',
      opt4: 'Recuperar senha',
      opt5: 'Alterar palavra-passe',
      opt6: 'Como entrar através do CC?',
    },
    recoveryPass: 'Indique o seu endereço de email',
    goBack: 'Voltar',
    recovery: 'Recuperar',
    footerText: {
      text1: 'Ainda não tem conta iDOK?',
      text2: 'Peça-nos uma demonstração',
      text3: 'e experimente gratuitamente o iDOK!'
    },
    loginBannerText:{
      text1: 'Assine em qualquer lugar',
      text2: 'com a Assinatura Eletrónica GTS',
      knowMore: 'Saiba mais',
    },
    wrongCredentials: 'Utilizador ou Password incorreto',
    error:'Ocorreu um erro',
    goback: 'Voltar',
    page404: {
      title: 'Ups!',
      subTitle: 'Parece-me perdido no meio de tantos documentos!',
      description: 'Volte ao iDOK que nós ajudamos.'
    },
    page500: {
      title: 'Crash!',
      subTitle: 'Erro interno no servidor',
      description: 'Felizmente, está tudo alojado na cloud!'
    },
    page403: {
      title: 'Oh, não!',
      subTitle: 'Acesso Negado',
      description: 'Pedimos desculpa, mas não tem autorização para aceder ao solicitado'
    },
    page401: {
      title: 'Ups!',
      subTitle: 'Autorização Requerida',
      description: 'Por favor efetue o seu login e aceda à sua Gestão Documental na cloud.'
    },
    acinGroup: {
      leftSide: {
        title: {
          quality: 'Qualidade e ',
          security: 'Segurança ',
          rest: 'na Gestão Documental'
        },
        firstText: {
          start: 'A elevada qualidade que carateriza os produtos da solução ',
          end: ', empresa do Grupo ACIN, resulta de uma rigorosa monitorização de procedimentos internos que garantem a melhoria contínua dos serviços e promovem o aumento dos níveis de satisfação dos clientes.',
          link: 'ACIN - iCloud Solutions Lda'
        },
        iso9001: 'NORMA ISO 9001: ',
        iso9001Desc: 'Garante os compromissos de qualidade e satisfação dos serviços prestados pela ACIN.',
        iso27001: 'NORMA ISO 27001: ',
        iso27001Desc: 'Garante a confidencialidade, integridade e privacidade da informação gerida pelas nossas plataformas.',
        iso20000: 'NORMA ISO 20000: ',
        iso20000Desc: 'Garante o cumprimento de níveis de serviços definidos para os nossos clientes e fornecedores.',
        lastText: 'Apenas um número restrito de empresas portuguesas integra uma certificação tecnológica de tão elevado rigor.'
      },
      rightSide: {
        platform: '+ plataformas ',
        rest: 'da ACIN - iCloud Solutions Lda',
        knowMore: 'Saiba mais',
        from: 'da',
        acinGroup: 'ACIN - iCloud Solutions Lda',
      },
      platforms: {
        igest: {
          title: 'iGEST',
          desc: 'Software de Faturação',
          url:'igest.pt'
        },
        juntas: {
          title: 'Juntas Online',
          desc: 'Serviço de Juntas De Freguesia',
          url: 'juntasonline.pt'
        },
        ifreg: {
          title: 'iFREG',
          desc: 'Software de Gestão de Freguesias',
          url: 'ifreg.pt'
        },
        paypay: {
          title: 'PayPay',
          desc: 'Solução de Pagamentos',
          url: 'paypay.pt'
        },
        gts: {
          title: 'GTS',
          desc: 'Prestadora de Serviços de Confiança',
          url: 'globaltrustedsign.com'
        },
        acingov: {
          title: 'acinGov',
          desc: 'Plataforma Eletrónica de Compras Públicas',
          url: 'acingov.pt'
        },
        imed: {
          title: 'iMED',
          desc: 'Software de Gestão Clínica',
          url: 'imed.pt'
        },
        iParque: {
          title: 'iParque',
          desc: 'Software de Gestão de Estacionamentos',
          url: 'iparque.pt'
        },
        irh: {
          title: 'iRH',
          desc: 'Software de Gestão de Assiduidade',
          url: 'irh.pt'
        },
        cestado: {
          title: 'C. Estado',
          desc: 'Oportunidades de Negócio',
          url: 'comprasdoestado.pt'
        },
        ilink: {
          title: 'ilink',
          desc: 'EDI e Faturação Eletrónica',
          url: 'ilink.pt'
        },
        taxi: {
          title: 'omeutáxi',
          desc: 'Software de Faturação para Motoristas de Táxi',
          url: 'omeutaxi.pt'
        },
        agromarket: {
          title: 'Agromarket',
          desc: 'Plataforma de venda de produtos agrícolas',
          url: 'agromarket.pt'
        },
        denuncias: {
          title: 'P. Denúncias',
          desc: 'Plataforma de gestão de denúncias',
          url: 'portaldedenuncias.pt'
        }
      }
    },
    acin: 'ACIN group',
    microsoftPlatform: 'Plataforma de Produtividade Avançada',
    salesforce: 'Salesforce',
    selosTemp: 'Selos temporais',
    cardReader: 'Leitores de Cartões',
    boxEmails: 'Caixa de Emails',
    emailRequired: 'Email obrigatório',
    validEmail: 'Deve ser um email valido',
    passwordRequired: 'Password obrigatório',
    recoveryPassError: 'Ocorreu um erro ao tentar recuperar o seu password',
    nameRequired: 'Nome obrigatório',
    phoneRequired: 'Telefone obrigatório',
    onlyNumbers: 'O número de telefone é obrigatório',
    countryRequired: 'O país é obrigatório',
    termsRequired: 'Deverá confirmar as Condições Gerais de Adesão',
    policyRequired: 'Deverá confirmar a Política de Privacidade',
    policyLink: 'https://acin.pt/politica-privacidade/',
    workersRequired: 'O número de funcionários é obrigatório',
    entityTypeRequired: 'O tipo de entidade é obrigatório',
    recoveryPassEmailSent: 'O email para a sua recuperação de password foi enviado com sucesso.',
    newPassword: 'Nova senha',
    newPasswordConfirmation: 'Confirmação da nova senha',
    passwordRules: {
      title: 'A sua palavra-passe deve:',
      rule1: 'Ter pelo menos 10 caracteres.',
      rule2: 'Conter 3 destes elementos: ',
      rule3: 'Letras minúsculas; ',
      rule4: 'Letras maiúsculas; ',
      rule5: 'Números; ',
      rule6: 'Caracteres especiais.',
      rule7: 'Atingir nível forte.'
    },
    veryWeak:'Muito Fraco',
    weak: 'Fraco',
    moderate: 'Moderado',
    hard: 'Forte',
    modify: 'Alterar',
    wrongConfirmPassword: 'A palavra-passe não coincide',
    passwordAlterSuccessfully: ' A sua palavra-passe foi alterada com sucesso',
    enterWithCC: 'Como entrar através do CC?',
    subscribeCCError: 'Ocorreu um erro ao tentar subscrever-se com o CC',
    submit: 'Submeter',
    subscribeCCText: 'Para que possa aceder usando o seu Cartão de Cidadão, deverá ativar esta forma de acesso.',
    sentEmail: 'O email foi enviado com sucesso',
    fieldRequired: 'O campo deverá ser preenchido',
    sponsorText: 'Parceiros Grupo ACIN',
    acinGroupPlatforms: 'Plataformas',
    conectionWithPortals: 'Possibilidade de interligação com portal de serviços',
    accessCentralized: 'Acesso centralizado da informação',
    alertsAndNotifications: 'Alertas e notificações',
    clasificationPlan: 'Plano de classificação',
    protectedInformation: 'Classificação de informação protegida',
    signOnAuthentication: 'Autenticação Single Sign-On',
    relatories: 'Relatórios e estatísticas',
    quality: 'Qualidade',
    mainAnimation: 'Gestão documental',
    mobileAnimation: 'App de Gestão Documental',
    phonesAnimation: 'Assinatura digital na app idok',
    acinVideo: 'Transformação digital',
    mobileHome: {
      mainTitle: {
        line1: 'Já conhece a app',
        line2: {
          default: 'de ',
          color: 'Gestão Documental?'
        }
      },
      secondaryTitle: 'app idok',
      paragraphs: {
        text1: 'Apresentamos-lhe a extensão da sua solução de Gestão Documental, que lhe oferece mais flexibilidade e mobilidade na hora de gerir a sua informação.',
        text2: {
          init: 'A nossa app de Gestão Documental possui integração com a prestadora de serviços de Confiança ',
          middle: ' e permite-lhe, caso possua uma ',
          bold: ' assinar digitalmente os documentos a partir do seu telemóvel.',
          link1: 'Global Trusted Sign',
          link2: 'assinatura qualificada da GTS,'
        },
      },
      button: 'Saber Mais',
      imageAlt: 'Ecrãs da app de Gestão Documental iDOK'
    },
    mobile: {
      linkBold: 'novo',
      mainTitle: {
        line1: 'A app inovadora de ',
        line2: {
          color: 'Gestão Documental'
        },
      },
      mainParagraph: {
        init: 'Uma app de Gestão Documental que lhe garante ',
        bold: 'mobilidade total',
        end: '! Faça a gestão dos seus documentos e assine-os digitalmente de forma rápida, simples e segura. Disponível para sistemas operativos '
      },
      secondaryTitle: {
        bold: 'Mobilidade Total ',
        normal: 'com total ',
        color: 'Segurança'
      },
      secondaryParagraph: 'A app iDOK é como uma extensão da sua solução de Gestão Documental, um complemento que lhe oferece mais flexibilidade e mobilidade na hora de gerir a sua informação.',
      thirdTitle: {
        color: 'A Assinatura Digital',
        normal: 'na palma da sua mão'
      },
      mobileImgAlt: {
        img1: "Encaminhamento de documento para utilizadores ou departamentos, na app de Gestão Documental.",
        img2: "Ecrã com a informação do documento, entidades associadas e encaminhamentos realizados, na app iDOK.",
        img3: "Ecrã inicial da app de Gestão Documental com os dois tipos de solução.",
        img4: "Listagem de documentos recebidos, com filtros de pesquisa, na app iDOK.",
        img5: "Ecrã com a inserção da assinatura digital no documento, na app iDOK."
      },
      thirdParagraph: {
        text1: 'São cada vez mais os documentos transacionados por via eletrónica, com necessidade de autenticação.',
        text2: 'Esta validação pode ser feita mediante a aposição de uma assinatura digital qualificada, promovendo a transformação digital na sua organização.',
        text3: {
          init: 'A app de Gestão Documental iDOK está integrada com a prestadora de serviços de confiança ',
          link1: 'Global Trusted Sign (GTS)',
          middle: ', que disponibiliza ',
          link2: 'certificados de assinaturas digitais',
          end: ' de várias tipologias, nomeadamente a singular e coletiva.'
        },
        text4: {
          init: 'Dispense meios de autenticação que obriguem à instalação de hardware ou software e ',
          bold: 'assine os seus documentos diretamente na app do iDOK, com a assinatura digital GTS.'
        }
      },
      forthTitle: {
        normal: 'Conheça as principais valências da ',
        color: 'app iDOK'
      },
      list: listMobile,
      forthParagraph: {
        init: 'Tire partido de uma app de Gestão Documental, simples e intuitiva, que possui ',
        bold: 'integração com a prestadora de serviços de Confiança Global Trusted Sign',
        end: ', e que lhe permite, caso possua uma assinatura qualificada da GTS, assinar digitalmente os documentos a partir do seu telemóvel.'
      },
      listCards: [
        { bold: 'Seja notificado', normal: 'em tempo real', alt: 'Notificações push da app iDOK' },
        { bold: 'Consulte', normal: 'toda a informação', alt: 'Acesso a todos os documentos pela app iDOK' },
        { bold: 'Assine digitalmente', normal: 'com a GTS', alt: 'Assinatura digital com a GTS' },
        { bold: 'Encaminhe', normal: 'os documentos', alt: 'Envio de documentos pela app iDOK' },
      ],
      meta: {
        title: 'App iDOK - Gestão Documental com total segurança',
        description: 'Uma app de Gestão Documental que lhe garante mobilidade total. Faça a gestão dos seus documentos e assine-os digitalmente de forma rápida, simples e segura.',
        keywords: 'segurança, autenticação, gestão Documental, documentos, assinatura digital, desmaterialização, transição digital'
      }
    },
    primavera: {
      meta: {
        title: 'Integração iDOK com ERP Primavera - Automatize a sua Gestão Documental',
        description: 'A integração com o ERP Primavera permite gerar e obter dados financeiros diretamente da gestão documental. Assim, as empresas podem agilizar toda a gestão financeira de forma eficiente e precisa, sendo que contribui para fluxos de trabalho mais eficazes. Em suma, permite informatizar todo o processo do pedido de requisição, diminui o risco de duplicação de informação e previne erros.'
      }
    },
    gestaoSistemasContent: {
      meta: {
        title: 'Gestão de Sistemas Eficaz - iDOK',
        description: 'Alcance a eficiência máxima na Gestão de Sistemas em Portugal. Automatize processos, promova desmaterialização e assegure a rastreabilidade dos seus documentos. Descubra como agora!'
      },
      imgTitles: {
        mainAnimation: 'Soluções Avançadas de Gestão de Sistemas para uma Eficiência Empresarial',
        gestaoEficienteImg: 'Solução Integral para uma Gestão de Sistemas Eficiente. Automatização, Desmaterialização para otimizar Fluxos de Trabalho'
      },
      tools: {
        confidencialidadeDados: 'Confidencialidade de dados privados do utilizador',
        confidencialidadeDadosAlt: 'Garantia da Confidencialidade dos Dados do Utilizador para uma Gestão de Sistemas Segura e Confiável',
        autorizacaoAcesso: 'Acesso autorizado à plataforma e à documentação, incluindo para Auditores',
        autorizacaoAcessoAlt: 'Controlo de Acesso: Garantia do Acesso Autorizado à Plataforma e Documentação',
        limitacaoUtilizador: 'Limita o acesso do utilizador a determinados documentos',
        limitacaoUtilizadorAlt: 'Restrição de Acesso a Documentos',
        registoOperacoes: 'Regista todas as operações bem como o agente executor, através da monitorização dos intervenientes na Gestão Documental',
        registoOperacoesAlt: 'Registo de operações e utilizadores: Garantia de Transparência na Gestão de Documentos'
      },
      introductionContainer: {
        mainTitleOne: 'Faça uma ',
        mainTitleTwo: 'Gestão de Sistemas eficaz ',
        mainTitleThree: 'com o iDOK',
        introductionTextOne: 'A eficiência operacional no seio de uma Organização, independentemente do seu sector ou dimensão, pode ser desafiante.',
        introductionTextTwo: 'Com este desafio em cima da mesa, o iDOK, um software atento às necessidades de mercado, lança o ',
        introductionTextThree: 'Módulo Gestão de Sistemas',
        introductionTextFour: ', para que os utilizadores tenham nas suas mãos o conjunto certo de ferramentas para alcançar os objetivos pretendidos.'
      },
      advantagesContainer: {
        mainTitleOne: 'O que permite a ',
        mainTitleTwo: 'nova funcionalidade do iDOK',
        mainTitleThree: 'neste campo?'
      },
      essencialContainer: {
        title: 'O que torna o iDOK essencial para uma Gestão de Sistemas eficiente?',
        mainParagraphOne: 'Embora seja fundamental para o sucesso no mundo corporativo, existem poucas soluções com valências suficientes que garantam uma gestão integrada à altura do quotidiano de uma Entidade.',
        mainParagraphTwo: 'Assim sendo, esta nova funcionalidade é uma mais-valia que permite otimizar fluxos de trabalho, possibilita a automação de tarefas, aposta na desmaterialização da informação e assegura a rastreabilidade dos documentos.',
        mainParagraphThreeFirst: 'Em última instância, o iDOK com o seu ',
        mainParagraphThreeSecond: 'Módulo de Gestão de Sistemas',
        mainParagraphThreeThird: ', de mãos dadas com a Gestão Documental, é a resposta que procura para fins de Auditoria, já que será um forte aliado no cumprimento de políticas, processos e procedimentos, de acordo com responsabilidades definidas, níveis de acesso, bem como o estado de atualização da documentação.'
      },
      prioridadeContainer: {
        titleOne: 'A nossa prioridade é assegurar',
        titleTwo: 'a gestão, a integridade e a segurança documental'
      },
      areasContainer: {
        titleOne: 'Em que áreas o ',
        titleTwo: 'Módulo de Gestão de Sistemas iDOK ',
        titleThree: 'pode fazer a diferença?',
        mainParagraphOne: 'Com esta ',
        mainParagraphTwo: 'nova funcionalidade do iDOK ',
        mainParagraphThree: 'poderá apostar numa gestão integrada, de modo a promover uma cultura organizacional de excelência.',
        areaOne: 'Alimentar',
        areaTwo: 'Formação',
        areaThree: 'Ambiental',
        areaFour: 'Segurança de Informação',
        areaFive: 'Gestão de Qualidade',
        areaSix: 'RGPD',
        areaSeven: 'Gestão de Equipamentos',
        areaEight: 'Anticorrupção',
        areaNine: 'Segurança e Saúde no Trabalho',
        areaTen: 'Gestão de Recursos Humanos',
        areaEleven: 'Atendimento',
        areaTwelve: 'Gestão de Reclamações',
      }
    },
    onlyoffice: {
      meta: {
        title: 'Edite e Crie Documentos com a integração iDOK-ONLYOFFICE',
        description: 'Edite e crie documentos em diversos formatos diretamente no iDOK, eliminando a necessidade de ferramentas externas. Otimize a gestão documental com a integração iDOK-ONLYOFFICE e garanta eficiência e praticidade às suas equipas.'
      },
      introductionContainer: {
        mainOpening: 'O QUE É O ONLYOFFICE?',
        mainTitleOne: 'Edite e Crie Documentos com a ',
        mainTitleTwo: 'integração iDOK-ONLYOFFICE',
        bannerTitle: 'ONLYOFFICE: Ferramenta de Edição de Documentos Online integrada com o iDOK',
        startInfo: 'A partir de agora trabalhar com o iDOK é ainda mais fácil com esta integração!',
        mainInfo: 'O ONLYOFFICE é uma ferramenta que permite a edição de documentos online. Oferece uma solução de criação de ficheiros, como textos, folhas de cálculo e apresentações. É uma alternativa ao Microsoft 365, com a possibilidade de criar, editar e salvar documentos nos formatos compatíveis com Word, Excel, PowerPoint e PDF.'
      },
      allowContainer: {
        mainTitleOne: 'O que permite a ',
        mainTitleTwo: 'integração com o ONLYOFFICE?',
        mainText: 'Permitirá às suas equipas gerir de forma eficiente a criação e edição de documentos de forma simples e sofisticada, tornando-se uma das plataformas mais completas do mercado.'
      },
      infoOnlyoffice: {
        titleImg: 'Integração iDOK-ONLYOFFICE: Eficácia na Criação e Edição de Documentos',
        fileGroup: 'ONLYOFFICE: Formatos compatíveis com Word, Excel, PowerPoint e PDF',
        paragraphOne: 'A utilização de um Sistema de Gestão Documental resulta numa fonte de informação sobre as atividades do seu negócio, e é fundamental para a tomada de decisões. Os documentos são um recurso valioso que contêm informações críticas para qualquer Entidade ou Organização, sendo importante que estas preservem os documentos de forma organizada e válida.',
        paragraphTwo: 'O processo de circulação de documentos internos ou externos, bem como o processamento da informação dos mesmos, consome tempo e está sujeito a erros, que podem ser evitados se as Entidades ou Organizações optarem por soluções que lhes permitam gerir os seus documentos de forma automatizada.'
      },
      pros: {
        mainTitleOne: 'Quais são as ',
        mainTitleTwo: 'vantagens da integração ',
        mainTitleThree: 'com o ONLYOFFICE?',
        paragraphOne: 'Esta nova solução, ajudará a sua Entidade a ',
        paragraphTwo: 'organizar, manter e controlar documentos de forma fácil, ',
        paragraphThree: 'sendo possível acompanhar todo o ciclo de vida do documento com automatização.',
        listTitle: 'Algumas das novas vantagens são:',
        listFirstOne: 'Criar e editar ficheiros em diversos formatos, ',
        listFirstTwo: 'como docx, xlsx, pptx, odt, ott, entre outros, aumentando a flexibilidade para os utilizadores. Esta funcionalidade estará disponível para os restantes módulos da plataforma iDOK, como os Modelos, Fluxos, Processos, Portal de Serviços.',
        listSecondOne: 'Integração de plataformas, ',
        listSecondTwo: 'onde não existe a necessidade de alterar entre diferentes ferramentas ou interfaces.',
        listThirdOne: 'Integração com os fluxos de trabalho existentes ',
        listThirdTwo: 'na plataforma iDOK, o que garante uma transição agradável e uma experiência de utilizador consistente.',
        listFourth: 'Possibilidade de editar documentos do Word, Excel e PowerPoint mesmo sem o Office instalado.'
      }
    },
    selosTemporais: {
      meta: {
        title: 'Selos Temporais na Gestão Documental - Integração iDOK',
        description: 'Os Selos Temporais atestam a data/hora de criação, envio ou receção de um documento ou de transação eletrónica e/ou comercial. São, por isso, fundamentais para uma Gestão Documental rigorosa e eficaz. Confira autenticidade, integridade e segurança aos seus documentos enviados eletronicamente.'
      },
      tools: {
        documentoVinculado: 'Certificam a existência de um documento vinculado a um horário específico (data/hora)',
        documentoVinculadoAlt: 'Garantia da hora da criação/alteração de documento',
        documentoModificado: 'Provam que o documento não foi modificado',
        documentoModificadoAlt: 'Evita a manipulação de documentos'
      },
      introductionContainer: {
        mainTitleOne: 'Selos Temporais',
        mainTitleTwo: 'na Gestão Documental',
        question: 'O que são Selos Temporais?',
        questionAnswer: 'Os Selos Temporais atestam a data/hora de criação, envio ou receção de um documento ou uma transação eletrónica e/ou comercial. São, por isso, fundamentais para uma Gestão Documental rigorosa e eficaz.'
      },
      advantagesContainer: {
        mainTitleOne: 'O que asseguram os',
        mainTitleTwo: 'Selos Temporais',
        mainTitleThree: 'a uma Entidade?'
      },
      securityContainer: {
        title: 'Qual é a importância dos Selos Temporais na Gestão Documental?',
        mainParagraphOne: 'No âmbito da Gestão Documental é fundamental que possa conferir autenticidade, integridade e segurança aos seus documentos, uma vez que, atualmente praticamente toda a transação documental ocorre eletronicamente.',
        mainParagraphTwo: 'Nesse sentido, é fundamental garantir que os seus documentos se mantêm confiáveis ao longo do tempo e que possam, deste modo, ser utilizados como evidência em processos legais ou administrativos. Com efeito, a utilização de Selos Temporais evita a manipulação de documentos transacionados digitalmente.',
        mainParagraphThree: 'Ao adquirir Selos Temporais, está a incrementar a robustez e a segurança da sua Gestão Documental. Em última instância, está ainda a investir na credibilidade dos seus documentos, algo fundamental para auditorias, registos administrativos ou processos judiciais.',
        securityShieldTitle: 'Segurança na Gestão Documental'
      },
      gtsIntegrationContainer: {
        mainTitleOne: 'Selos Temporais GTS',
        mainTitleTwo: 'Global Trusted Sign',
        mainParagraphOne: 'Para que possa assegurar a melhor Gestão Documental do mercado à sua Entidade, o iDOK está integrado com a Prestadora de Serviços de Confiança GTS - Global Trusted Sign, que oferece Selos Temporais Interoperáveis válidos e confiáveis.',
        mainParagraphTwo: 'Mantenha a credibilidade e segurança da sua Gestão Documental com a ajuda da integração do iDOK com a ',
        mainParagraphThree: 'Global Trusted Sign',
        mainParagraphFour: '. Conheça o precário dos Selos Temporais e comece já a investir na autenticidade da sua documentação!',
        buyNow: 'Comprar já',
        gtsIntegrationAlt: 'Integração do Software de Gestão Documental iDOK com os Selos Temporais da GTS'
      }
    },
    integracaoIdok: 'INTEGRAÇÃO iDOK',
    gestaoSistemasMainTitle: 'GESTÃO DE SISTEMAS',
    whatIs: 'O que é o',
    conceitoPrimaveraUm: 'O',
    conceitoPrimaveraTres: 'ERP Primavera',
    conceitoPrimaveraQuatro: 'é um sistema de gestão integrada dos processos de finanças, contabilidade, compras, inventário, produção, ativos, marketing, recursos humanos, entre outros.',
    conceitoPrimaveraDois: 'É um software que aposta na centralização da informação, sendo que fornece funcionalidades que permitem otimizar os processos internos das organizações.',
    toolsTitleOne: 'O que permite a ',
    toolsTitleTwo: 'integração do iDOK',
    toolsTitleThree: 'com o ERP Primavera?',
    sincronizacaoArtigosText: 'Sincronização de fornecedores, artigos, centro de custos, atividades, entre outros',
    sincronizacaoArtigosAlt: 'Sincronização de fornecedores, artigos, custos',
    documentosFinanceirosText: 'Criação e obtenção de detalhes de documentos financeiros',
    documentosFinanceirosAlt: 'Criação de Documentos Financeiros',
    estadoEncomendasText: 'Consulta do estado de encomendas',
    estadoEncomendasAlt: 'Consultar o estado da encomenda',
    elaboracaoProcedimentosText: 'Elaboração de procedimentos de contratação pública no módulo de contrato',
    elaboracaoProcedimentosAlt: 'Criar procedimentos de contratação pública',
    usabilidadeTitleUm: 'Como funciona ',
    usabilidadeTitleDois: 'o processo de usabilidade para o utilizador final?',
    usabilidadePassoUm: 'O pedido é iniciado na Gestão Documental através de um fluxo desenhado à medida do funcionamento da sua organização',
    usabilidadePassoDois: 'É invocado o ERP Primavera, para a obtenção de informações pertinentes, como por exemplo, artigos, centros de custos, entre outros',
    usabilidadePassoTres: 'O ERP Primavera devolve as informações ou documentos financeiros',
    usabilidadePassoQuatro: 'O processo de integração está concluído',
    imagemPrimaveraAltTitle: 'Ecrãs do Software de Gestão Documental iDOK e do ERP Primavera',
    integracoesVantagensTitleUm: 'Quais são as',
    integracoesVantagensTitleDois: 'vantagens',
    integracoesVantagensTitleTres: 'desta integração?',
    integracoesVantagensTextoUm: 'A integração com o ERP Primavera permite gerar e obter dados financeiros diretamente da Gestão Documental. Assim, as empresas podem',
    integracoesVantagensTextoDois: 'agilizar toda a gestão financeira de forma eficiente e precisa',
    integracoesVantagensTextoTres: 'sendo que contribui para fluxos de trabalho mais eficazes. Em suma, permite informatizar todo o processo do pedido de requisição, diminui o risco de duplicação de informação e previne erros',
    integracoesProcessoTitleUm: 'Um',
    integracoesProcessoTitleDois: 'processo integrado',
    integracoesProcessoTitleTres: 'simplifica a Gestão Documental?',
    integracoesProcessoTextoUm: 'A integração direta do iDOK com a solução',
    integracoesProcessoTextoDois: 'EDI ilink - Faturas Eletrónicas',
    integracoesProcessoTextoTres: 'agiliza o processo de transmissão de faturas, de forma automática, eficaz e segura.',
    integracoesProcessoTextoQuatro: 'Outra funcionalidade integrada é a',
    integracoesProcessoTextoCinco: 'Assinatura Digital da GTS - Global Trusted Sign',
    integracoesProcessoTextoSeis: 'que possibilita assinar documentos à distância, o que permite mais flexibilidade na sua Gestão Documental.',
    duvidasTitle: 'Ficou com dúvidas?',
    integrationDiagramImg: 'ptDiagramaIntegracao',
    integrationDiagramTitleAlt: 'Integração do Software de Gestão Documental iDOK com o ERP Primavera',
    callUiIntroTitle: 'Precisa de ajuda?',
    callUiIntroSubtitle: 'Torne a sua gestão documental mais simples e intuitiva.',
    callUiIntroDescription: 'Desmaterialize e organize toda a documentação, em qualquer lugar.',
    callUiIntroButtonTitle: 'Contactem-me!',
    callUiCallMeSubtitle: "Introduza o seu contacto telefónico. Brevemente iremos contactá-lo(a), de forma gratuita e sem compromissos.",
    callUiCallMeDescription: 'Esta chamada destina-se exclusivamente a contactos comerciais.',
    callUiCallMeAvailability: 'Serviço disponível nos dias úteis das 09h00 às 18h00.',
    callUiCallMeNumberPlaceholder: '+351 999999999',
    callUiCallMePolicyText: 'Li e aceito a <a target="_blank" rel="noopener noreferrer"><u>Política de Privacidade</u><a/>',
    callUiCallMeButtonTitle: 'Contacte-me',
    callUiCallMeTitle: "Ligamos gratuitamente",
    callUiCallMeValidationInvalidNumber: "Introduza um número de telefone válido",
    callUiCallMeValidationInvalidPolicy: "É obrigatório aceitar a política de privacidade",
    callUiSuccessIconUrl: '',
    callUiSuccessTitle: 'Contacto enviado',
    callUiSuccessSubtitle: 'Muito obrigado',
    callUiSuccessDescription: 'Nos próximos minutos será contactado pela linha comercial iDOK.',
    callUiErrorMessage: 'Ocorreu um erro. Tente novamente',
  },
};
